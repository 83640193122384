import React from "react";

const HorizontalCards = () => {
  return (
    <div className="card-footer box-container">
      <div className="row">
        <div className="col-sm-3 col-6">
          <div className="description-block border-right">
            <h5 className="description-header">{12}</h5>
            <span className="description-text">Success Tx/Orders</span>
          </div>
        </div>
        <div className="col-sm-3 col-6">
          <div className="description-block border-right">
            <h5 className="description-header">{20}</h5>
            <span className="description-text">OnGoing Orders</span>
          </div>
        </div>
        <div className="col-sm-3 col-6">
          <div className="description-block border-right">
            <h5 className="description-header">{45}</h5>
            <span className="description-text">Cancel Tx/Orders</span>
          </div>
        </div>
        <div className="col-sm-3 col-6">
          <div className="description-block">
            <h5 className="description-header">{30}</h5>
            <span className="description-text">Expire Orders</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalCards;
