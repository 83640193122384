import React, { useEffect, useState } from "react";
import axios from "axios";

const Help = () => {
  const [openAccordion, setOpenAccordion] = useState(0);
  const [faqData, setFaqData] = useState([]);

  const fetchPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/faq/list`)
        .then((res) => {
          console.log("res", res);
          setFaqData(res?.data?.data);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  console.log("faqData", faqData);

  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <title>Help</title>

      <div className="container">
        <div className="static-page-des">
          {/* <h1>Help</h1> */}

          <div className="accordion">
            {faqData.map((faq, index) => (
              <div key={faq?.id} className="accordion-item">
                <div
                  className="accordion-header"
                  onClick={() => toggleAccordion(index)}
                >
                  <h2>{faq?.question}</h2>
                </div>
                {openAccordion === index && (
                  <div className="accordion-content">
                    <p>{faq?.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
