import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomPagination from "../../components/CustomPagination";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate } from "../../helper/helper";

const Offer = () => {
  const [offers, setOffers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const { offersType } = useParams();

  console.log("offersType is : ", offersType);

  const fetchOffers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/offers/admin-offers-list`,
        {
          headers: {
            "api-key": "12345-abcde-67890-fghij",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 50,
            offersType: offersType,
          },
        }
      );

      setOffers(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [currentPage, searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log("currentPage", currentPage);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Offer Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {/* <input
                      type="text"
                      placeholder="Search users..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    /> */}
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Currency</th>
                          <th>Price Type</th>
                          <th>Offer Type</th>
                          <th>Timestamp</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {offers?.length > 0 ? (
                          offers.map((offer, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>

                              <td>{offer?.cryptoCurrency}</td>
                              <td>{offer?.tradePriceType}</td>
                              <td>{offer?.offerType}</td>
                              <td>{formatDate(offer?.createdAt)}</td>

                              <td>
                                <Link
                                  to={`/offer-view/${offer?._id}`}
                                  className="btn btn-link merch-view"
                                >
                                  <i className="fas fa-eye"></i>
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
