import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Reports from "../../components/charts/Reports";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/transactions/dashboard-data`,
        {
          headers: {
            "api-key": "12345-abcde-67890-fghij",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDashboardData(response?.data);
    } catch (error) {
      console.error("Error fetching user count:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{dashboardData?.usersCount}</h3>
                    <p>Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link to="/users" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{dashboardData?.totalOffers}</h3>
                    <p>Offers</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link to="/offer/all" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{dashboardData?.walletTxCount}</h3>
                    <p>Wallet Transactions</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link
                    to="/transaction-management"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{dashboardData?.cryptoTxCount}</h3>
                    <p>Crypto Transactions</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link
                    to="/transaction-management"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{dashboardData?.activeSellOffer}</h3>
                    <p>Active Sell Offers</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link
                    to="/offer/active-sell-offers"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{dashboardData?.activeBuyOffer}</h3>
                    <p>Active Buy Offers</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link
                    to="/offer/active-buy-offers"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Reports />
      </div>
    </div>
  );
};

export default Dashboard;
