import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomPagination from "../../components/CustomPagination";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate } from "../../helper/helper";

const TransactionManagement = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("WALLET");

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/transactions/list-in-admin`,
        {
          headers: {
            // "api-key": "12345-abcde-67890-fghij",
            "api-key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 50,
            search: searchQuery,
            transactionType: activeTab,
          },
        }
      );

      setTransactions(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, activeTab]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  console.log("currentPage", currentPage);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Transaction Management</h1>
              </div>
              <div className="col-sm-3">
                <h4
                  className={`tab ${
                    activeTab === "WALLET" ? "active-tab" : ""
                  }`}
                  onClick={() => handleTabChange("WALLET")}
                >
                  Wallet
                </h4>
              </div>
              <div className="col-sm-3">
                <h4
                  className={`tab ${
                    activeTab === "CRYPTO" ? "active-tab" : ""
                  }`}
                  onClick={() => handleTabChange("CRYPTO")}
                >
                  Crypto
                </h4>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Transaction Type</th>
                          <th>
                            {activeTab === "WALLET" ? (
                              <>Amount</>
                            ) : (
                              <>BTC Amount</>
                            )}
                          </th>
                          {activeTab === "CRYPTO" && <th>Usd Amount</th>}
                          <th>Status</th>
                          <th>Sender</th>
                          <th>Receiver</th>
                          <th>Timestamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions?.length > 0 ? (
                          transactions.map((transaction, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{transaction?.transactionType}</td>
                              {/* <td>
                                {transaction?.symbol}
                                {transaction?.amount}
                              </td> */}
                              <td>
                                {activeTab === "WALLET" ? (
                                  <>
                                    {transaction?.symbol}
                                    {transaction?.amount}
                                  </>
                                ) : (
                                  <>{transaction?.btcAmount} BTC</>
                                )}
                              </td>
                              {activeTab === "CRYPTO" && (
                                <td>{transaction?.usdAmount} usd</td>
                              )}

                              <td>{transaction?.status}</td>
                              <td>{transaction?.senderDetails?.name}</td>
                              <td>{transaction?.receiverDetails?.name}</td>
                              <td>{formatDate(transaction?.createdAt)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionManagement;
