import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const ViewNotification = () => {
  const [notification, setNotification] = useState({});
  const { notificationId } = useParams();

  useEffect(() => {
    const fetchNotificationDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/notification/view`,
          {
            params: { id: notificationId },
          }
        );
        console.log("responseresponse", response);
        setNotification(response?.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchNotificationDetails();
  }, [notificationId]);

  console.log("notification123", notification);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Notification Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    {notification && notification?.notification && (
                      <>
                        <div className="user-info">
                          <strong>Title:</strong>{" "}
                          {notification?.notification?.title}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Description:</strong>{" "}
                          {notification?.notification?.body}
                        </div>
                        <br />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotification;
