import React, { useEffect, useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { formatDate } from "../../helper/helper";

const TransactionTable = ({ transactions, activeTab }) => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>S.N.</th>
                <th>Transaction Type</th>
                <th>
                  {activeTab === "WALLET" ? <>Amount</> : <>BTC Amount</>}
                </th>
                {activeTab === "CRYPTO" && <th>Usd Amount</th>}
                <th>Status</th>
                <th>Sender</th>
                <th>Receiver</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.length > 0 ? (
                transactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{transaction?.transactionType}</td>
                    <td>
                      {activeTab === "WALLET" ? (
                        <>
                          {transaction?.symbol}
                          {transaction?.amount}
                        </>
                      ) : (
                        <>{transaction?.btcAmount} BTC</>
                      )}
                    </td>
                    {activeTab === "CRYPTO" && (
                      <td>{transaction?.usdAmount} usd</td>
                    )}

                    <td>{transaction?.status}</td>
                    <td>{transaction?.senderDetails?.name}</td>
                    <td>{transaction?.receiverDetails?.name}</td>
                    <td>{formatDate(transaction?.createdAt)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <p>Data not found</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransactionTable;
