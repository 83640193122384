import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UpdateAboutUs = () => {
  const [page, setPage] = useState({});
  const [pageName, setPageName] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    subTitle: "",
    heading1: "",
    description1: "",
    heading2: "",
    description2: "",
    heading3: "",
    description3: "",
    heading4: "",
    subHeading4: "",
    heading5: "",
    subHeading5: "",
    heading6: "",
    subHeading6: "",
  });
  const [errors, setErrors] = useState({});
  const { pageId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPageDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pages/about-us-view`,
          {
            params: { id: pageId },
          }
        );
        setPage(response?.data);
        setPageName(response?.data?.page?.name);
        setFormData({
          title: response?.data?.page?.title,
          subTitle: response?.data?.page?.subTitle,
          heading1: response?.data?.page?.heading1,
          description1: response?.data?.page?.description1 || "",

          heading2: response?.data?.page?.heading2,
          description2: response?.data?.page?.description2 || "",

          heading3: response?.data?.page?.heading3,
          description3: response?.data?.page?.description3 || "",

          heading4: response?.data?.page?.heading4,
          subHeading4: response?.data?.page?.subHeading4,

          heading5: response?.data?.page?.heading5,
          subHeading5: response?.data?.page?.subHeading5,

          heading6: response?.data?.page?.heading6,
          subHeading6: response?.data?.page?.subHeading6,
        });
      } catch (error) {
        console.error("Error fetching page details:", error);
      }
    };
    fetchPageDetails();
  }, [pageId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange1 = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description1: data,
    }));
  };

  const handleEditorChange2 = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description2: data,
    }));
  };

  const handleEditorChange3 = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description3: data,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData?.title?.trim()) {
      newErrors.title = "Title is required";
    } else if (!formData?.subTitle?.trim()) {
      newErrors.subTitle = "Sub Title is required";
    }

    if (!formData.heading1.trim()) {
      newErrors.heading1 = "heading1 is required";
    }

    if (!formData.heading2.trim()) {
      newErrors.heading2 = "heading2 is required";
    }

    if (!formData.heading3.trim()) {
      newErrors.heading3 = "heading3 is required";
    }

    if (!formData.heading4.trim()) {
      newErrors.heading4 = "heading4 is required";
    }

    if (!formData.heading5.trim()) {
      newErrors.heading5 = "heading5 is required";
    }

    if (!formData.heading6.trim()) {
      newErrors.heading6 = "heading6 is required";
    }

    if (!formData.description1.trim()) {
      newErrors.description1 = "Description1 is required";
    }

    if (!formData.description2.trim()) {
      newErrors.description2 = "Description2 is required";
    }

    if (!formData.description3.trim()) {
      newErrors.description3 = "Description3 is required";
    }

    if (!formData.subHeading4.trim()) {
      newErrors.subHeading4 = "subHeading4 is required";
    }

    if (!formData.subHeading5.trim()) {
      newErrors.subHeading5 = "subHeading5 is required";
    }

    if (!formData.subHeading6.trim()) {
      newErrors.subHeading6 = "subHeading6 is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/pages/about-us-update`,
        {
          id: pageId,
          ...formData,
        }
      );
      toast.success("Page details updated successfully!");
      navigate("/content-management");
    } catch (error) {
      console.error("Error updating content details:", error);
      alert("Failed to update content details.");
    }
  };

  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }
    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);
            console.log("formData", formData);

            axios
              .post(
                `${process.env.REACT_APP_API_URL}/pages/file-upload`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                resolve({
                  default: response?.data?.url,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
      );
    }

    abort() {}
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  console.log("errors", errors);
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{pageName}</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {page && page?.page && (
                        <>
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className={`form-control ${
                                errors.title ? "is-invalid" : ""
                              }`}
                              placeholder="Enter title"
                              value={formData?.title}
                              onChange={handleInputChange}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {errors.title}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="subTitle">Sub Title</label>
                            <input
                              type="text"
                              name="subTitle"
                              className={`form-control ${
                                errors.subTitle ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Sub Title"
                              value={formData?.subTitle}
                              onChange={handleInputChange}
                            />
                            {errors.subTitle && (
                              <div className="invalid-feedback">
                                {errors.subTitle}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="heading1">Heading 1</label>
                            <input
                              type="text"
                              name="heading1"
                              className={`form-control ${
                                errors.heading1 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Heading1"
                              value={formData?.heading1}
                              onChange={handleInputChange}
                            />
                            {errors.heading1 && (
                              <div className="invalid-feedback">
                                {errors.heading1}
                              </div>
                            )}
                          </div>

                          {formData && (
                            <div className="form-group">
                              <label htmlFor="description1">Description1</label>

                              <CKEditor
                                editor={ClassicEditor}
                                data={formData?.description1}
                                onChange={handleEditorChange1}
                                config={{
                                  extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                              />
                              {errors.description1 && (
                                <div className="invalid-feedback d-block">
                                  {errors.description1}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="form-group">
                            <label htmlFor="heading2">Heading 2</label>
                            <input
                              type="text"
                              name="heading2"
                              className={`form-control ${
                                errors.heading2 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Heading2"
                              value={formData?.heading2}
                              onChange={handleInputChange}
                            />
                            {errors.heading2 && (
                              <div className="invalid-feedback">
                                {errors.heading2}
                              </div>
                            )}
                          </div>

                          {formData && (
                            <div className="form-group">
                              <label htmlFor="description2">Description2</label>

                              <CKEditor
                                editor={ClassicEditor}
                                data={formData?.description2}
                                onChange={handleEditorChange2}
                                config={{
                                  extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                              />
                              {errors.description2 && (
                                <div className="invalid-feedback d-block">
                                  {errors.description2}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="form-group">
                            <label htmlFor="heading3">Heading 3</label>
                            <input
                              type="text"
                              name="heading3"
                              className={`form-control ${
                                errors.heading3 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Heading3"
                              value={formData?.heading3}
                              onChange={handleInputChange}
                            />
                            {errors.heading3 && (
                              <div className="invalid-feedback">
                                {errors.heading3}
                              </div>
                            )}
                          </div>

                          {formData && (
                            <div className="form-group">
                              <label htmlFor="description3">Description3</label>

                              <CKEditor
                                editor={ClassicEditor}
                                data={formData?.description3}
                                onChange={handleEditorChange3}
                                config={{
                                  extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                              />
                              {errors.description3 && (
                                <div className="invalid-feedback d-block">
                                  {errors.description3}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="form-group">
                            <label htmlFor="heading4">Heading 4</label>
                            <input
                              type="text"
                              name="heading4"
                              className={`form-control ${
                                errors.heading4 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Heading4"
                              value={formData?.heading4}
                              onChange={handleInputChange}
                            />
                            {errors.heading4 && (
                              <div className="invalid-feedback">
                                {errors.heading4}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="subHeading4">SubHeading 4</label>
                            <input
                              type="text"
                              name="subHeading4"
                              className={`form-control ${
                                errors.subHeading4 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter sub Heading"
                              value={formData?.subHeading4}
                              onChange={handleInputChange}
                            />
                            {errors.subHeading4 && (
                              <div className="invalid-feedback">
                                {errors.subHeading4}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="heading4">Heading 5</label>
                            <input
                              type="text"
                              name="heading5"
                              className={`form-control ${
                                errors.heading5 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Heading4"
                              value={formData?.heading5}
                              onChange={handleInputChange}
                            />
                            {errors.heading5 && (
                              <div className="invalid-feedback">
                                {errors.heading5}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="subHeading5">SubHeading 5</label>
                            <input
                              type="text"
                              name="subHeading5"
                              className={`form-control ${
                                errors.subHeading5 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter sub Heading"
                              value={formData?.subHeading5}
                              onChange={handleInputChange}
                            />
                            {errors.subHeading5 && (
                              <div className="invalid-feedback">
                                {errors.subHeading5}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="heading6">Heading 6</label>
                            <input
                              type="text"
                              name="heading6"
                              className={`form-control ${
                                errors.heading6 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Heading6"
                              value={formData?.heading6}
                              onChange={handleInputChange}
                            />
                            {errors.heading6 && (
                              <div className="invalid-feedback">
                                {errors.heading6}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="subHeading6">SubHeading 6</label>
                            <input
                              type="text"
                              name="subHeading6"
                              className={`form-control ${
                                errors.subHeading6 ? "is-invalid" : ""
                              }`}
                              placeholder="Enter sub Heading6"
                              value={formData?.subHeading6}
                              onChange={handleInputChange}
                            />
                            {errors.subHeading6 && (
                              <div className="invalid-feedback">
                                {errors.subHeading6}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAboutUs;
