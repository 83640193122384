import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Login = ({}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter email.");
      return;
    }
    setError("");

    if (!password) {
      setError("Please enter password.");
      return;
    }
    setError("");
    // Validate email format
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    setError("");
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
          email,
          password,
        })
        .then((response) => {
          console.log("responseeeeEData", response);
          console.log("responseeee", response.data.access_token);
          if (response.data.access_token) {
            // setIsAuthenticated(response.data.access_token)
            localStorage.setItem("token", response?.data?.access_token);
            toast.success("Login successfully!");
            navigate("/dashboard");
          } else {
            setError("Invalid email or password");
          }
        })
        .catch((error) => {
          console.error("Error fetching user types:", error);
        });
    } catch (error) {
      console.log("catch error", error);
      setError("Invalid email or password.");
    }
  };

  //show password
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <b style={{ fontSize: "Larger", color: "white" }}>Login</b>
        </div>
        {/* /.login-logo */}
        <div className="card login-card">
          <div className="card-body register-card-body">
            {/* <p className="login-box-msg">Login</p> */}
            {error && <p className="text-danger">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    {/* <span className="fas fa-eye" /> */}
                    <span
                      
                      onClick={togglePasswordVisibility}
                    >
                      <i
                        className={
                          passwordVisible ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">
                    Login
                  </button>
                </div>
              </div>
            </form>
            <div className="social-auth-links text-center">
              <p>- OR -</p>
              <Link to="/forget-password" className="btn btn-block btn-danger">
                Forget Password
              </Link>
              {/* <a href="#" className="btn btn-block btn-danger">
                Reset Password
              </a> */}
            </div>
          </div>
        </div>
      </div>
      {/* /.login-box */}
    </>
  );
};

export default Login;
