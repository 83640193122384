import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import "./Report.css";
import jsPDF from "jspdf";
// import { HiOutlineDownload } from "react-icons/hi";
import html2canvas from "html2canvas";
import HorizontalCards from "./HorizontalCards";

const Reports = () => {
  const [hoverInfo, setHoverInfo] = useState(null);

  const [revenueChart, setRevenueChart] = useState({});
  const [cryptoMarginsChart, setCryptoMarginsChart] = useState([]);
  const [merchantDeposits, setMerchantDeposits] = useState([]);
  const [merchantWithdrawals, setMerchantWithdrawals] = useState([]);
  const [activePaymentLinks, setActivePaymentLinks] = useState(0);
  const [activeMerchantApps, setActiveMerchantApps] = useState(0);
  const [chartTime, setChartTime] = useState(0);
  const [timeLabels, setTimeLabels] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  // const chartRef3 = useRef(null);
  // const chartRef4 = useRef(null);

  const handleMouseEnter = (info) => {
    setHoverInfo(info);
  };

  const handleMouseLeave = () => {
    setHoverInfo(null);
  };

  const colors = [
    "rgb(54,162,235)", // ETH
    "rgb(246,99,132)", // BNB
    "rgb(248,159,64)", // USDT.*
    "rgb(251,205,86)",
    "rgb(75,192,192)",
    "rgb(255,99,132)",
    "rgb(153,102,255)",
    "rgb(255,159,64)",
    "rgb(54,162,145)",
    "rgb(104,162,235)",
    "rgb(164,162,235)",
    "rgb(204,162,235)",
    "rgb(144,162,235)",
  ];

  const paymentLinkChart = {
    Crypto: {
      January: 30,
      February: 20,
      March: 40,
      April: 35,
      May: 50,
      June: 45,
      July: 50,
      August: 40,
      September: 45,
      October: 50,
      November: 40,
      December: 35,
    },
    Fiat: {
      January: 15,
      February: 25,
      March: 30,
      April: 25,
      May: 20,
      June: 35,
      July: 30,
      August: 25,
      September: 20,
      October: 25,
      November: 30,
      December: 25,
    },
  };

  useEffect(() => {
    const createDataset = (token, index) => ({
      label: token,
      data: timeLabels.map((period) =>
        paymentLinkChart[token] ? paymentLinkChart[token][period] : 0
      ),
      borderColor: colors[index % colors.length],
      backgroundColor: "white",
    });

    const datasets = Object.keys(paymentLinkChart).map(createDataset);

    const data = {
      labels: timeLabels,
      datasets: datasets,
    };

    const config = {
      type: "line",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Transaction Count",
          },
        },
      },
    };

    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef1);
    chartRef1.current = new Chart(document.getElementById("lineChart"), config);
  }, [paymentLinkChart]);

  // Second Chart
  useEffect(() => {
    const data = {
      labels: timeLabels,
      datasets: [
        // {
        //   label: "Crypto Tx Count",
        //   data: [1001, 215, 3125, 4124, 5124, 612, 700, 458, 9361, 1000, 1951],

        //   borderColor: "rgb(246,99,132)",
        //   backgroundColor: "rgb(246,99,132)",
        //   stack: "combined",
        //   type: "bar",
        // },
        {
          label: "Crypto Tx Amount",
          data: [100, 15, 20, 22, 15, 60, 67, 78, 9, 100, 101],
          borderColor: "rgb(54,162,235)",
          backgroundColor: "rgb(54,162,235)",
          stack: "combined",
        },
        {
          label: "Fiat Tx Amount",
          data: [100, 15, 20, 22, 15, 60, 67, 78, 9, 100, 101],
          borderColor: "rgb(246,99,132)",
          backgroundColor: "rgb(246,99,132)",
          stack: "combined",
        },
      ],
    };

    const config = {
      type: "line",
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: "Transaction Amount",
          },
        },
        scales: {
          y: {
            stacked: true,
          },
        },
      },
    };

    const destroyChart = (chartRef) => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };

    destroyChart(chartRef2);
    chartRef2.current = new Chart(
      document.getElementById("stacked-bar"),
      config
    );
  }, [revenueChart]);

  return (
    <div>
      <div className="content-header">
        <section className="content">
          <HorizontalCards />
          <div className="container-fluid">
            <div className="row mb-2 report-container">
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label htmlFor="yearSelect">Select Year:</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <select
                    id="yearSelect"
                    className="form-control"
                    onChange={(e) => setChartTime(e.target.value)}
                  >
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </select>
                </div>
              </div>

              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <button
                  type="button"
                  class="btn btn-primary mr-3"
                  // onClick={exportMultipleChartsToPdf}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ margin: "0 5px" }}>Download Report</span>
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid main-diagram" id="all-chart">
            <div className="row mb-2 diagram-parent">
              <div className="col-sm-6 chart-parent line-chart">
                <canvas id="lineChart"></canvas>
              </div>
              <div className="col-sm-6 chart-parent stacked-chart">
                <canvas id="stacked-bar"></canvas>
              </div>
            </div>
            {/* <div className="row mb-2 diagram-parent">
              <div className="col-sm-6 chart-parent line-chart doughnut-chart">
                <canvas id="doughnutChart"></canvas>
              </div>
              <div className="col-sm-6 chart-parent">
                <canvas id="vertical-chart"></canvas>
              </div>
            </div> */}
          </div>
          <div className="chart-info">
            <span className="text">{hoverInfo}</span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Reports;
