import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomPagination from "../../components/CustomPagination";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate, truncateText } from "../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/notification/list`,
        {
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: searchQuery,
          },
        }
      );

      setNotifications(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [currentPage, searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
    // setCurrentPage(totalPages);
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-3">
                <h1 className="m-0">Notification Management</h1>
              </div>
              <div className="col-sm-1">
                <Link to="/notification-send">
                  <button className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} />
                    {" Add Notification"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {/* <input
                      type="text"
                      placeholder="Search users..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    /> */}
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Timestamp</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notifications?.length > 0 ? (
                          notifications.map((notification, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{notification?.title}</td>
                              <td>{truncateText(notification?.body, 100)}</td>
                              <td>{formatDate(notification?.createdAt)}</td>

                              <td>
                                <Link
                                  to={`/notification-view/${notification?._id}`}
                                  className="btn btn-link merch-view"
                                >
                                  <i className="fas fa-eye"></i>
                                </Link>
                                {/* <Link
                                  to={`/users-edit/${user?._id}`}
                                  className="btn btn-link merch-edit"
                                >
                                  <i className="fas fa-edit"></i>
                                </Link> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
