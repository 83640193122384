import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import defaultProfilePic from "../../assets/noImage.png"; // Import the default image
import QRCode from "react-qr-code";
import TransactionTable from "../TransactionManagement/TransactionTable";
import CustomPagination from "../../components/CustomPagination";

const UserView = () => {
  const [user, setUser] = useState({});
  const [activeTab, setActiveTab] = useState("WALLET");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const { userId } = useParams();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/transactions/user-transactions`,
        {
          headers: {
            "api-key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 50,
            // search: searchQuery,
            transactionType: activeTab,
            userId: "66c6ca04c6d365bc777a3c43",
          },
        }
      );

      setTransactions(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/view`,
        {
          params: { id: userId },
        }
      );
      setUser(response?.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, activeTab]);

  console.log("user : ", user);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">User Profile & Wallet Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    {user && user?.user && (
                      <>
                        <div className="user-info">
                          <strong>Name:</strong> {user?.user?.name}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>UserName:</strong> {user?.user?.userName}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Email:</strong> {user?.user?.email}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Contact Number:</strong> +
                          {user?.user?.countryCode +
                            "-" +
                            user?.user?.contactNumber}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Wallet Balance:</strong> $
                          {user?.user?.balance}
                        </div>
                        <br />
                        {/* BTC Balanceses */}
                        <div className="user-info">
                          <strong>Available BTC Amount :</strong> $
                          {user?.user?.cryptoBalance?.btc?.availableAmount}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Reserved BTC Amount :</strong> $
                          {user?.user?.cryptoBalance?.btc?.reservedAmount}
                        </div>
                        <br />

                        <div className="user-info">
                          <strong>Total BTC Amount :</strong> $
                          {user?.user?.cryptoBalance?.btc?.totalAmount}
                        </div>
                        <br />

                        <div className="user-info">
                          <strong>Profile Pic:</strong>
                          <br />
                          <div className="profile-pic-wrapper">
                            <img
                              src={user?.user?.imageUrl || defaultProfilePic}
                              alt="Profile"
                              className="profile-pic"
                            />
                          </div>
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Fiat Payment QR:</strong>
                          <div
                            style={{
                              height: "auto",
                              margin: "0 auto",
                              maxWidth: 150,
                              width: "100%",
                            }}
                          >
                            <QRCode
                              size={256}
                              style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                              }}
                              value={
                                user?.user?.countryCode +
                                user?.user?.contactNumber
                              }
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {/* User Table  */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">User Transaction Management</h1>
              </div>
              <div className="col-sm-3">
                <h4
                  className={`tab ${
                    activeTab === "WALLET" ? "active-tab" : ""
                  }`}
                  onClick={() => handleTabChange("WALLET")}
                >
                  Wallet
                </h4>
              </div>
              <div className="col-sm-3">
                <h4
                  className={`tab ${
                    activeTab === "CRYPTO" ? "active-tab" : ""
                  }`}
                  onClick={() => handleTabChange("CRYPTO")}
                >
                  Crypto
                </h4>
              </div>
            </div>
            <TransactionTable
              transactions={transactions}
              activeTab={activeTab}
            />
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
