import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SendNotification = () => {
  const [formData, setFormData] = useState({
    title: "",
    body: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Object.keys(formData).forEach((key) => {
    //   const value = formData[key];
    //   if (!value.trim()) {
    //     newErrors[key] = `${
    //       key.charAt(0).toUpperCase() + key.slice(1)
    //     } is required`;
    //   } else if (value.length > 1000) {
    //     newErrors[key] = `${
    //       key.charAt(0).toUpperCase() + key.slice(1)
    //     } not more then 1000 words`;
    //   }
    // });

    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!formData.body.trim()) {
      newErrors.body = "Description is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/notification/send-multiple`,
        formData
      );
      toast.success("Notification send successfully!");
      navigate("/notification");
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("Failed to sending notification. Please try again.");
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Notification Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <>
                        <div className="form-group">
                          <label htmlFor="question">Title</label>
                          <input
                            type="text"
                            name="title"
                            className={`form-control ${
                              errors.title ? "is-invalid" : ""
                            }`}
                            value={formData?.title}
                            onChange={handleChange}
                            placeholder="Enter title"
                            maxLength={100}
                          />
                          {errors.title && (
                            <div className="invalid-feedback">
                              {errors.title}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="body">Description</label>
                          <textarea
                            type="text"
                            name="body"
                            className={`form-control ${
                              errors.body ? "is-invalid" : ""
                            }`}
                            value={formData?.body}
                            onChange={handleChange}
                            placeholder="Enter notification"
                            maxLength={500}
                          />
                          {errors.body && (
                            <div className="invalid-feedback">
                              {errors.body}
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendNotification;
